import styled from "styled-components";
import { COLORS } from "../../styles/globals/colors";
import { TABLET_992 } from "../../styles/globals/sizes";

export const FaqContainer = styled.div`
  width: 100%;
  padding: 80px 0 78px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media (max-width: ${TABLET_992}px) {
    padding: 0 21px 78px;
  }
`;

export const Question = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font: normal normal normal 24px/32px Roboto;
  color: ${COLORS.vermilion};

  @media (max-width: ${TABLET_992}px) {
    font: normal normal normal 20px/26px Roboto;
  }
`;

export const Response = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  font: normal normal normal 20px/26px Roboto;
  color: ${COLORS.grayText};

  &:first-child {
    margin-top: 20px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1034px;
  min-height: 84px;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid ${COLORS.vermilion};
  border-radius: 20px;

  @media (max-width: ${TABLET_992}px) {
    padding: 24px 15px;
  }
`;

export const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
`;

export const Arrow = styled.img`
  width: 32px;
  height: 32px;
  transform: rotate(270deg);

  @media (max-width: ${TABLET_992}px) {
    width: 28px;
    height: 28px;
  }
`;

export const ArrowOpen = styled.img`
  width: 32px;
  height: 32px;
  transform: rotate(90deg);

  @media (max-width: ${TABLET_992}px) {
    width: 28px;
    height: 28px;
  }
`;

export const ResponseContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
