import React from "react";

import Phone from "./assets/phone-call.svg";

import {
  ContactUsContainer,
  ContactUsext,
  PhoneIcon,
  ContactUsButton,
  ContactUsDiv,
  SmallText,
  TextSection,
  BoldText,
} from "./styles";

const ContactUs = () => {
  return (
    <ContactUsContainer>
      <div data-aos="fade" className="container">
        <ContactUsDiv id="contact_us">
          <TextSection>
            <PhoneIcon src={Phone} alt="phone icon" />
            <ContactUsext>
              Have more questions about hospice or want to learn more about the
              services life provides?
              <SmallText>
                Call us at
                <BoldText>973-607-4930</BoldText>. At Life Hospice, we’re
                dedicated to delivering compassionate care. Discover how we can
                help you and your loved ones today.
              </SmallText>
            </ContactUsext>
          </TextSection>
          <ContactUsButton href={`/#callBack`}>Contact Us</ContactUsButton>
        </ContactUsDiv>
      </div>
    </ContactUsContainer>
  );
};

export default ContactUs;
